import { graphql } from "gatsby"
import React from "react"
import Listing from "./listing"
import md5 from "md5"
import Inflector from "inflector-js"

const GalleryListing = ({
                          data,
                          columns
                        }) => {

  const galleries = data
  //console.log(galleries)

  const getEntry = (entry) => {
    const { name: headline, images, count } = entry
    const sample_image = images[0] // using shift() led to some returns being blank when components relaoded...v odd
    const id = sample_image ? sample_image.folderNodeId : md5(headline)
    const processedHeadline = Inflector.humanize(headline.split("/").pop()) + ` (${count})`
    return {
      id: id,
      headline: processedHeadline,
      image: (sample_image ? sample_image.thumb.toString() : "http://placekitten.com/200/300"),
      slug: `/galleries/${Inflector.dasherize(headline)}`,
      collection: sample_image ? sample_image.folder : null
    }
  }

  const processEntries = () => {
    // console.log(galleries)
    let items = galleries.folders.map(entry => {
      return getEntry(entry)
    })
    return items
  }

  return (
    <Listing columns={columns} title={"Galleries"} children={processEntries()} />
  )
}

export const query = graphql`
#    fragment GalleryFolderFragment on Query {
#      galleryFolders: allCloudinaryFolder {
#        nodes {
#          id
#          name
#          path
#        }
#      }
#    }
    fragment GalleryListFragment on Query {
        galleries: allCloudinaryImage {
            folders: group(field: folder) {
                count: totalCount
                name: fieldValue
                images: nodes {
                    id
                    thumb
                    folder,
                    folderNodeId
                }
            }
        }
    }
`

export default GalleryListing