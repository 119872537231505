import React from "react"
import Gallery from "../components/galleryWidget/gallery"
import {
  Heading2,
  MajorColumn,
  MinorColumn,
  FlexWrapMobile, ArticleBody
} from "../components/content-elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AddThisInlineShare } from "../components/addthis_inline"
import Inflector from "inflector-js"
// import BlogListing from "../components/blog_listing"
import GalleryListing from "../components/gallery_listing"
import { graphql } from "gatsby"


const GalleryFolderTemplate = ({ data, pageContext, location }) => {
  const { folder } = pageContext
  const { galleries } = data


  return (
    <Layout location={location}>
      <SEO
        title={folder.name}
      />
      <FlexWrapMobile>


        <MajorColumn>
          <article className="gallery-folder-post" itemScope itemType="http://schema.org/ImageGallery">
            <header>
              <Heading2 itemProp="headline">{Inflector.humanize(folder.name.split("/").pop())}</Heading2>
            </header>
            <section itemProp="articleBody">
              <ArticleBody>
                <Gallery folder={folder.name} columns={"1,3,2,1,2,3,3,3,3,3,3,3,3,3,3"} />
              </ArticleBody>
            </section>
          </article>
        </MajorColumn>

        <MinorColumn>
          <AddThisInlineShare />
          <GalleryListing data={galleries} columns={2} />
        </MinorColumn>

      </FlexWrapMobile>


    </Layout>
  )
}

export const pageQuery = graphql`
    query galleryPageData {
        # dont need query for folder itself as pageContext contains all we need as its a widget
        # query in <Gallery />
        ...GalleryListFragment
    }
`

export default GalleryFolderTemplate