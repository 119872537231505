import React from "react"
import md5 from "md5"
import {
  TeaserParagraph,
  Heading3,
  Heading4,
  ActionButtons,
  TagPill,
  AdaptiveLink
} from "./content-elements"

const Listing = ({
                   columns,
                   children, // should be ListableItems
                   title,
                   actions //ActionButtons
                 }) => {

  const headerMarkup =
    <Heading3>{title}</Heading3>


  const getEntry = (entry) => {
    // console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE")
    // console.log(entry)
    const { id, headline, teaser, image, tag, slug, /* collection, actions, */ promote, promoteSerendpity } = entry

    const itemKey = id ? id : md5(headline + teaser)
    const linkKey = `${itemKey}_link`
    const imageKey = `${itemKey}_image`
    const promoteStyle = getPromoteLayout(promote)
    const doSerendpity = promoteSerendpity ? true : true
    const randomNumber = Math.floor(Math.random() * (8 - 1) + 1)
    const serendipity = (doSerendpity && randomNumber === 1 && columns > 2) ? `${getPromoteLayout(true)} serendipity-${randomNumber} promoteSerendpity-${promoteSerendpity}` : `serendipity-${randomNumber}`
    const imageUrl = image //|| 'https://res.cloudinary.com/finalarena/image/upload/v1608466226/img_497680_wjpx8a.png'

    return (
      <div key={itemKey}
           className={`overflow-hidden bg-white hover:bg-warmGray-50 shadow-md hover:shadow-xl border border-transparent hover:border-primary-bright rounded ${promoteStyle} ${serendipity} inline-block`}>
        {(
          tag ? <TagPill>{tag}</TagPill> : ''
        )}
        <AdaptiveLink key={linkKey} to={slug}>
          <div className={`relative h-full`}>

            {(
              imageUrl ? <img key={imageKey} className="inline-block rounded min-h-0" src={imageUrl}
                              alt="" /> : ""
            )}
            <div className="mt-4 px-4">
              <Heading4>{headline}</Heading4>
              <TeaserParagraph>{teaser}</TeaserParagraph>
            </div>
          </div>
        </AdaptiveLink>
      </div>
    )
  }

  const getEntries = (entries) => {
    let items = entries.map(entry => {
      return getEntry(entry)
    })
    return items
  }

  // got to be done this way, as the purge css needs to see classes you wish to keep :(
  // makes dynamic class names tricky
  const getColumnLayout = () => {
    const cssColunmns = columns || 1

    switch (cssColunmns) {
      case 1:
        return (`grid-cols-1 md:grid-cols-1`)
      case 2:
        return (`grid-cols-1 md:grid-cols-2`)
      case 3:
        return (`grid-cols-2 md:grid-cols-3`)
      case 4:
        return (`grid-cols-2 md:grid-cols-4`)
      default:
        throw new Error("unsupported grid listing layout - please enhance this method")
    }
  }

  const getPromoteLayout = (promote) => {
    return promote ? "col-span-2" : ""
  }


  const content = (
    <>
      <div className={`grid grid-flow-row-dense ${getColumnLayout()} gap-2 lg:gap-4 pt-4`}>
        {getEntries(children)}
      </div>
    </>
  )

  const footerMarkup =
    actions ? (
      <div className={`inline-block`}>
        <ActionButtons actions={actions} />
      </div>
    ) : null

  return (
    <section className="pt-4 px-4 pb-4">
      {headerMarkup}
      {content}
      {footerMarkup}
    </section>
  )

}

export default Listing